$fontRoot: '../../../../../node_modules/@smapiot/components/fonts';

@import '~@piral/portal-core/styles/index.scss';

.login-form {
  display: flex;
  height: 100%;
  flex: 1 0 auto;
  align-content: center;
  flex-direction: column;
  margin: auto;
  max-width: 80vw;
  width: 30em;
}
